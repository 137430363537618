<template>
  <div>
    <v-text-field
      v-bind="input"
      :label="$translation.t(input.label)"
      outlined
      v-model="model"
      :rules="inputRules"
      hide-details="auto"
      :type="input.inputType"
    />
  </div>
</template>

<script>
export default {
  name: "TextInput",
  props: {
    input: {
      type: Object,
      required: true,
    },
    value: String,
  },
  data: () => ({
    model: "",
  }),
  created() {
    this.model = this.value ?? "";
  },
  computed: {
    inputRules() {
      return this.input?.rules || [];
    },
  },
  watch: {
    model(newValue) {
      this.$emit("input", newValue);
    },
    value(newValue) {
      this.model = newValue ?? "";
    },
  },
};
</script>
